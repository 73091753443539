@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

.intro_tap_wrap {
  @include flex_direction(column);
  width: 100%;
  height: 300px;
  min-height: 200px;
  padding: 20px 0;
  .intro_tap {
    @include justify_content(space-between);
    .intro_tap_label {
      padding: 5px;
      font-size: 20px;
      font-weight: $font-weight-bold;
    }
    .intro_tap_label[data-select="true"] {
      color: $sub-color;
      border-bottom: 4px solid $sub-color;
    }
    .intro_tap_label[data-select="false"] {
      color: #bebdbd;
    }
    .intro_tap_label[data-cursor="true"] {
      cursor: pointer;
    }
  
    .intro_tap_label[data-cursor="false"] {
      cursor: default;
    }
  }
  .intro_tap_content {
    padding: 40px 0;
    width: 500px;
    font-size: 20px;
    color: $black;
    font-weight: 300;
    line-height: 1.65;
  }
  .intro_tap_paging_wrap {
    @include justify-content(space-between);
    position: absolute;
    bottom: 280px;
    margin-top: 40px;
    .intro_tap_paging_active_button {
      width: 30px;
      height: 8px;
      border-radius: 20px;
      margin-right: 10px;
      background-color: $sub-color;
      cursor: pointer;
    }
    .intro_tap_paging_inactive_button {
      width: 8px;
      height: 8px;
      border-radius: 20px;
      margin-right: 10px;
      background-color: #d8d8d8;
      cursor: pointer;
    }
  }
}